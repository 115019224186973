import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CommentCard from '../components/CommentCard';
import { Label, TextInput, Button } from 'flowbite-react';
import Toasts from '../components/Toasts'
import { useSelector } from 'react-redux'
import { checkAuthorization } from '../services/AuthService';
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'


function ReviewsList({ handleAddReviewsClick }) {
    const showToast = useSelector(state => state.toast.show)
    const isAuth = useSelector(state => state.auth.isAuth)
    const [reviews, setReviews] = useState([]);
    const [url, setUrl] = useState();

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
          checkAuthorization()
        )
    
      }, [dispatch, checkAuthorization])
    
      if (isAuth === 'notAuth') {
        return <Navigate to='/login' />
      }


    const parseComment = (url) => {
        const apiUrl = `https://dev.nemo-monitoring.kz/api/v1/reviews/?url=${url}`;
        axios.get(apiUrl)
            .then(response => {
                setReviews(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <div>
            {showToast && <Toasts />}
            <div className="mb-2 mx-auto block w-144 py-4">
                <TextInput
                    id="kaspiLink"
                    type="text"
                    placeholder="https://kaspi.kz/shop/p/dreame-f9-belyi-100549614/?c=750000000#!/item"
                    required={true}
                    value={url}
                    onChange={value => setUrl(value.target.value)}
                   className='pb-2'
                />
                <Button className='w-full' onClick={e => parseComment(url)}>
                    Отобразить отзывы / обновить
                </Button>
            </div>
            {reviews.data?.map(review => (
                <div key={review.id}>

                    <CommentCard minus={review.comment.minus} rating={parseInt(review.rating)} like={review.feedback.positive} dislike={review.feedback.negative} commentId={review?.id} name={review.author} date={review.date} text={review.comment.text} plus={review.comment.plus} />
                </div>
            ))}
        </div>
    );
}

function ReviewPage() {
    const [selectedReviews, setSelectedReviews] = useState([]);

    const handleAddReviewsClick = () => {
        // TODO: добавить отмеченные отзывы в список
    };

    return (
        <div>
            <ReviewsList handleAddReviewsClick={handleAddReviewsClick} />
        </div>
    );
}

export default ReviewPage;
