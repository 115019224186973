import { createSlice } from '@reduxjs/toolkit'

const defaultAuthState = {
    currentUser: {},
    isAuth: 'loading',
    isRegistered: false,
    errors: '',
}

const AuthSlice = createSlice( {
    name: 'auth',
    initialState: defaultAuthState,
    reducers: {
        setUser(state, action) {
            state.isAuth = 'authed';
            state.currentUser = action.payload;
        },
        logOut(state) {
            state.isAuth = 'notAuth';
            state.currentUser = {};
            state.isRegistered = false;
        },
        checkAuth(state) {
            state.isAuth = 'authed';
        },
        setRegistered(state) {
            state.isRegistered = true;
            state.errors = '';
        },
        setError(state, action) {
            state.errors = action.payload;
        }
    },
})

export default AuthSlice.reducer
export const { setUser, logOut, checkAuth, setRegistered, setError } = AuthSlice.actions

// const defaultAuthState = {
//     currentUser: {},
//     isAuth: false
// }

// export function authReducer(state = defaultAuthState, action) {
//     switch (action.type) {
//         case actions.SET_USER:
//             return {
//                 ...state,
//                 currentUser: action.payload,
//                 isAuth: true
//             }

//         case actions.LOG_OUT:
//             return {
//                 ...state,
//                 currentUser: {},
//                 isAuth: false
//             }
//         default:
//             return state;
//     }
// }

// export const SetUser = (payload) => ({type: actions.SET_USER, payload})
// export const LogOut = () => ({type: actions.LOG_OUT})