import React, { useState, useEffect } from 'react';
import { Button, Modal, Spinner } from 'flowbite-react';
import { assetsApi } from '../services/SalesApi';
import { useDispatch } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import Exporting from 'highcharts/modules/exporting';

Exporting(Highcharts);

export function SaleGraphicModal({ code, openSalesModal, salesQuanity }) {
  const [openModal, setOpenModal] = useState(true);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const onClick = () => {
    setData([]);
    setOpenModal(false);
    openSalesModal(false);
  };

  useEffect(() => {
    async function fetchSales() {
      try {
        const response = await assetsApi.getSales(code);
        setData([].concat(response.data).reverse());
      } catch (error) {
        console.error(error);
      }
    }
    fetchSales(code);
  }, [dispatch, code]);

  return (
    <>
      <Modal show={openModal} onClose={onClick} size="7xl">
        <Modal.Header>График продаж. Общее количество продаж - {salesQuanity}</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {data.length > 2 ? (
              <SalesChart data={data} />
            ) : (
              <div className='text-center'>
                <Spinner size='xl' aria-label="Loading..." />
                <p className='text-gray-600 pt-2'>Загрузка данных ...</p>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClick}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function SalesChart({ data }) {
  const dates = data.map(item => item.date);

  const warehouseData = data.reduce((acc, cur) => {
    cur.warehouse_quant.forEach(item => {
      if (!acc[item.name]) {
        acc[item.name] = {
          quantity: Array(dates.length).fill(0),
          summa: Array(dates.length).fill(0),
        }
      }
      const index = dates.indexOf(cur.date);
      acc[item.name].quantity[index] = item.quantity;
      acc[item.name].summa[index] = item.summa;
    });
    return acc;
  }, {});

  const colors = ['rgba(75,192,192,1)', 'rgba(255,99,132,1)', 'rgba(255,206,86,1)', 'rgba(54,162,235,1)', 'rgba(153,102,255,1)', 'rgba(143,0,255)'];

  const series = Object.keys(warehouseData).map((key, i) => {
    const totalSales = warehouseData[key].quantity.reduce((sum, value) => sum + value, 0);
    return {
      name: `${key} (${totalSales})`,
      data: warehouseData[key].quantity,
      color: colors[i % colors.length]
    };
  });

  const totalQuantity = Object.values(warehouseData).reduce((acc, cur) => {
    return acc + cur.quantity.reduce((sum, value) => sum + value, 0);
  }, 0);

  const totalSalesData = dates.map(date => {
    const totalSales = Object.values(warehouseData).reduce((acc, cur) => {
      const index = dates.indexOf(date);
      return acc + cur.quantity[index];
    }, 0);
    return totalSales;
  });

  series.push({
    name: `Total Sales (actual info) - (${totalQuantity}) `,
    data: totalSalesData,
    color: 'rgba(255,165,0,1)'
  });

  const options = {
    plotOptions: {
      column: {
          point: {
              events: {
                  mouseOver: function() {
                      const point = this;
                      Highcharts.animate(point.graphic, {
                          scaleX: 1.1,
                          scaleY: 1.1
                      });
                  },
                  mouseOut: function() {
                      const point = this;
                      Highcharts.animate(point.graphic, {
                          scaleX: 1,
                          scaleY: 1
                      });
                  }
              }
          }
      }
  }
,  
    chart: {
      type: 'spline',
      zoomType: 'x', // добавляем возможность увеличения графика
  
      cardinalSplineTension: 1.5,
      style: {
        fontFamily: 'Arial, sans-serif'
      }
    },
    title: {
      text: 'Quantity of Goods in Warehouses and Total Sales'
    },
    xAxis: {
      categories: dates,
      title: {
        text: 'Date'
      }
    },
    yAxis: {
      title: {
        text: 'Quantity'
      }
    },
    plotOptions: {
      series: {
        marker: {
          radius: 4, // размер маркера
          states: {
            hover: {
              enabled: true,
              radius: 7, // увеличиваем размер маркера при наведении
              lineWidth: 1
            }
          }
        }
      },
      spline: {
        dataLabels: {
          enabled: true
        }
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemStyle: {
        fontWeight: 'lighter'
      }
    },
    series: series.map(s => ({
      ...s,
      fillOpacity: 0.2,
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 3
        }
      },
      threshold: null
    })
    ),
    exporting: {
      enabled: true,
  }
};



  return (
    <div className="overflow-x-auto min-w-max max-w-full">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default SaleGraphicModal;
