import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import Nav from './components/Navbar'
import {store} from './redux/index'
import { Provider } from 'react-redux';
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";


Sentry.init({
  dsn: "https://1f8a296baa204d6f9043d739ab931e86@o4504717010141184.ingest.sentry.io/4504795279785984",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <BrowserRouter>
    <Nav />
    <App />
  </BrowserRouter>
  </Provider>
);
