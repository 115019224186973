import axios from 'axios'
import { KASPI_OFFER_API_URL } from '../constants/constants'
import { setOffers } from '../redux/kaspiOffersSlice'


export function fetchKaspiOffers(props) {
	const code = props.code
	const city = props.city
	const local_url = `http://127.0.0.1:8000/kaspi/${code}?city=${city}`
	const data = {
		'code': code,
		'city': city
	}
	return async dispatch => {
		try {
			const response = await axios.get(local_url)
			dispatch(setOffers(response.data))
		} catch (e) {
			try {
				const response = await axios.post(KASPI_OFFER_API_URL(), data)
				dispatch(setOffers(response.data))
			} catch (e) {
				dispatch(setOffers({ 'status': 'loading' }))
			}
		}
	}
}
