import { createSlice } from '@reduxjs/toolkit'


const defaultProductState = {
    products: 'loading',
    detailProduct: 'loading',
    config: 'loading',
    showConfigModal: false,
    showKaspiModal: false,
    showStockModal: false,
    modalProductDeatil: 'loading',
    stockData: [],
    cleanData: false,
}

const ProductSlice = createSlice( {
    name: 'product',
    initialState: defaultProductState,
    reducers: {
        setProducts(state, action) {
            state.products = action.payload;
        },
        setDetailProduct(state, action) {
            state.detailProduct = action.payload;
        },
        setConfigProducts(state, action) {
            state.config = action.payload;
        },
        setShowConfigModal(state, action) {
            state.showConfigModal = action.payload;
        },
        setShowKaspiModal(state, action) {
            state.showKaspiModal = action.payload;
        },
        setShowStockModal(state, action) {
            state.showStockModal = action.payload;
        },
        setStockData(state, action) {
            state.stockData = action.payload;
        },
        setProductDetail(state, action) {
            state.modalProductDeatil = action.payload;
        },
        setCleanData(state, action) {
            state.cleanData = action.payload;
        },
        
    },
})

export default ProductSlice.reducer
export const {
    setProducts,
    setDetailProduct,
    setConfigProducts,
    setShowConfigModal,
    setShowKaspiModal,
    setProductDetail,
    setShowStockModal,
    setStockData,
    setCleanData,
 } = ProductSlice.actions