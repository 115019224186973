import { createSlice } from '@reduxjs/toolkit'


const defaultKaspiOffersState = {
    offers: {'status': 'loading'},
}

const kaspiOffersSlice = createSlice( {
    name: 'kaspiOffers',
    initialState: defaultKaspiOffersState,
    reducers: {
        setOffers(state, action) {
            state.offers = action.payload;
        },
        removeOffers(state) {
            state.offers = {'status': 'loading'};
        },
    },
})

export default kaspiOffersSlice.reducer
export const {
    setOffers,
    removeOffers,
 } = kaspiOffersSlice.actions