import React from 'react'
import { Toast } from 'flowbite-react'
import { HiCheck } from "react-icons/hi";
import { useSelector, useDispatch } from 'react-redux'
import { setShowToast } from '../redux/toastSlice';


function Toasts() {
    const dispatch = useDispatch()
    const showToast = useSelector(state => state.toast)
    
    setTimeout(() => {
        dispatch(setShowToast(false))
    }, showToast.duration);

    return (
        <div>
            {showToast.show && <div className='fixed right-4 top-0 z-50'>
                <Toast>
                    <div className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg ${showToast.status == 'success' ? 'bg-green-100 text-green-500' : 'bg-red-100 text-dark-500' }   dark:bg-green-800 dark:text-green-200`}>
                        <HiCheck className="h-5 w-5" />
                    </div>
                    <div className="ml-3 text-sm font-normal">
                        {showToast.text}
                    </div>
                    <Toast.Toggle />
                </Toast>
            </div>}

        </div>
    )
}

export default Toasts