import React, { useState } from 'react';

import ChartModal from './modals/Modal';  // Импортируем наш модальный компонент



function ProductList({ data }) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    const handleButtonClick = async (productCode, idValue) => {
        let id = `?code=${productCode}&id=${encodeURIComponent(idValue)}`
        setModalData(id);
        setIsModalOpen(true);
    };

    return (
        <div className="space-y-8">
            {data.data?.map(product => (
                <div key={product.sku} className="border p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                    <h2 className="text-xl font-semibold mb-4">
                        <a href={product.link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                            {product.title}
                        </a>
                    </h2>
                    <strong className="block text-gray-600">Код товара:</strong> {product.sku}
                    <div className="mt-4">
                        <strong className="block text-gray-600">Отчеты:</strong>
                        <ul className="list-decimal pl-5 mt-2">
                            {product.id.map(idValue => (
                                <li key={idValue} className="text-gray-700 flex items-center pb-2">
                                    {idValue[0]}
                                    {idValue[1] == 'inactive' && <span className="ml-2 text-gray-500">(Завершен)</span>}
                                    {idValue[1] == 'active' && <span className="ml-2 text-green-500">(В процесее)</span>}
                                    <button onClick={() => handleButtonClick(product.sku, idValue[0])} className="ml-4 px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                                        Подробнее
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
            {modalData &&
                <ChartModal id={modalData} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            }
        </div>
    );
}


export default ProductList;
