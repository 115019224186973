import React from 'react'
import LoginForm from '../components/AuthForm';


export const AuthPage = () => {
    return (<>
        <LoginForm />
    </>
    );
}


