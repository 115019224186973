import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { Button, Modal, Label, TextInput, Spinner, Table } from 'flowbite-react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowKaspiModal } from '../redux/productSlice'
import { fetchKaspiOffers } from '../services/KaspiOfferAPI'
import { removeOffers, setOffers } from '../redux/kaspiOffersSlice'
import { setToast } from '../redux/toastSlice';
import { patchProduct } from '../services/ProductAPI';


function ProductKaspiModal(props) {
    const [show, setShow] = useState(props.show)
    const dispatch = useDispatch()
    const kaspiOffers = useSelector(state => state.kaspiOffers.offers)
    const productDeatil = props.detail// useSelector(state => state.product.modalProductDeatil)
    let [almatyPrice, setAlmatyPrice] = useState(productDeatil['prices']['almaty_price'])
    const [astanaPrice, setAstanaPrice] = useState(productDeatil['prices']['astana_price'])
    const [regionPrice, setRegionPrice] = useState(productDeatil['prices']['region_price'])

    const kaspiCode = useMemo(() => {
        if (productDeatil.kaspiLink) {
            return productDeatil.kaspiLink.split('-').slice(-1)[0].split('/')[0]
        }

    })

    useEffect(() => {
        // async function fetchProductOffers() {
        //     const code = kaspiCode; // Replace 'YOUR_PRODUCT_CODE' with the actual product code you want to get offers for.
        //     const city = '750000000'; // Replace 'YOUR_CITY_CODE' with the actual city code, or use the default '750000000'.
        //     const cats = {}; // If you want to provide data for the 'cats' parameter, you can define it here.
      
        //     try {
        //       const response = await getOffers(code, city, cats);
        //       console.log(response.status); // This will print the result object containing status and offers to the browser console.
        //       // Update your component state or perform other actions with the response data as needed.
        //     } catch (error) {
        //       console.error(error); // Handle any errors that occurred during the process.
        //     }
        //   }

        //   fetchProductOffers()
        dispatch(
            removeOffers()
        )
        kaspiCode ?
            dispatch(
                fetchKaspiOffers({ 'code': kaspiCode, 'city': 750000000 })
            )
            :
            dispatch(
                setOffers({ 'status': 'nulled link' })
            )
    }, [dispatch])


    const onClick = () => {
        setShow(false)
        props.closeModal(false)
        dispatch(setShowKaspiModal(false))
    }

    const cities = [
        { 'title': 'Алматы', 'id': '750000000' },
        { 'title': 'Астана', 'id': '710000000' },
        { 'title': 'Караганда', 'id': '351010000' },
        { 'title': 'Шымкент', 'id': '511010000' },
        { 'title': 'Павлодар', 'id': '551010000' },
        { 'title': 'Ускаман', 'id': '631010000' }]

    const saveData = () => {
        props.closeModal(false)
        console.log(almatyPrice, astanaPrice, regionPrice)
        // props.setAlmatyPrice(almatyPrice, astanaPrice, regionPrice)
        const priceData = {
            "code": productDeatil.code,
            "prices": {
                "almaty_price": almatyPrice,
                "astana_price": astanaPrice,
                "region_price": regionPrice,
            }
        }
        dispatch(patchProduct(priceData))

        setShow(false)
        dispatch(setShowKaspiModal(false))

        dispatch(setToast(
            {
                'status': 'success',
                'text': 'Цены успешны изменены',
                'duration': 2000,
                'show': true
            }
        ))
    };
    
    let errorLog = kaspiOffers

    return (
        <Fragment>
            <Modal
                show={show}
                onClose={onClick}
                size="4xl"
            >
                <Modal.Header>
                    Цены в 1С & цены конкурентов
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <div className='sm:flex gap-20'>
                            <div className='grid grid-cols-2 w-64 h-40 '>
                                <div className='grid grid-cols-1 gap-3 items-center'>
                                    <div>
                                        <Label
                                            htmlFor="price_almaty"
                                            value="г. Алматы"
                                        />
                                    </div>
                                    <div>
                                        <Label
                                            htmlFor="price_almaty"
                                            value="г. Астана"
                                        />
                                    </div>
                                    <div>
                                        <Label
                                            htmlFor="price_regions"
                                            value="Регионы"
                                        />
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 gap-1'>
                                    <div className="flex items-center">

                                        <div className='w-24'>
                                            <TextInput
                                                id="price_almaty"
                                                type="text"
                                                sizing="xl"
                                                value={almatyPrice}
                                                onChange={e => setAlmatyPrice(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center">

                                        <div className='w-24'>
                                            <TextInput
                                                id="price_astana"
                                                type="text"
                                                sizing="xl"
                                                value={astanaPrice}
                                                onChange={e => setAstanaPrice(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center">

                                        <div className='w-24'>
                                            <TextInput
                                                id="price_regions"
                                                type="text"
                                                sizing="xl"
                                                value={regionPrice}
                                                onChange={e => setRegionPrice(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full mx-auto xs:pt-4 xl:pt-0'>
                                <Cities cities={cities} />

                                <div className='mx-auto pt-3 h-60 overflow-y-auto'>
                                    <Table
                                        hoverable={true}
                                        striped={true}>
                                        <Table.Head>
                                            <Table.HeadCell>
                                                Магазин
                                            </Table.HeadCell>
                                            <Table.HeadCell>
                                                Цена
                                            </Table.HeadCell>
                                            <Table.HeadCell>
                                                Доставка
                                            </Table.HeadCell>
                                        </Table.Head>

                                        {kaspiOffers.status === 'loading' ?
                                            <Table.Body className="divide-y animate-pulse" role="status">
                                                {[1, 2, 3, 4, 5].map(x =>
                                                    <Table.Row key={x} className=" dark:border-gray-700 dark:bg-gray-800">
                                                        <Table.Cell className='whitespace-nowrap font-medium text-gray-900 dark:text-white'>
                                                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                                        </Table.Cell>
                                                    </Table.Row>)
                                                }
                                            </Table.Body>
                                            : kaspiOffers.status === 'nulled link' || kaspiOffers.status === 'Connection error' || kaspiOffers.status === 'Error data' || kaspiOffers.status === 'No offers' ?
                                                <Table.Row className=" dark:border-gray-700 dark:bg-gray-800">
                                                    <Table.Cell className=' font-medium text-gray-900 dark:text-white'>
                                                        <div className="h-2.5 rounded-full dark:bg-gray-700 mb-2">{kaspiOffers.status === 'nulled link' ? 'Отсутствует ссылка' :
                                                            kaspiOffers.status === 'Error data' ? 'Ошибка! Отправлено уведомление Арсену' :
                                                                kaspiOffers.status === 'No offers' ? 'Отсутсвуют магазины' :
                                                                    'Проверьте подключение к интернету'}</div>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                                    </Table.Cell>
                                                </Table.Row>
                                                :
                                                kaspiOffers['offers'].map((offer, index) =>
                                                    <KaspiProductList
                                                        key={index}
                                                        offer={offer}
                                                    />
                                                )
                                        }
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={saveData}>
                        Сохранить
                    </Button>
                    <Button
                        color="gray"
                        onClick={onClick}
                    >
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}


function Cities(props) {
    const cities = props.cities
    const productDeatil = useSelector(state => state.product.modalProductDeatil)

    const kaspiCode = useMemo(() => {
        if (productDeatil.kaspiLink) {
            return productDeatil.kaspiLink.split('-').slice(-1)[0].split('/')[0]
        }

    })

    const dispatch = useDispatch()

    const getOffers = (id) => {
        dispatch(
            removeOffers()
        )

        kaspiCode ?
            dispatch(
                fetchKaspiOffers({ 'code': kaspiCode, 'city': id })
            )
            :
            dispatch(
                setOffers({ 'status': 'nulled link' })
            )
    }

    return (
        <div className='overflow-x-auto'>
            <Button.Group>
                {
                    cities.map(city =>

                        < Button color="gray" key={city.id}
                            onClick={e => getOffers(city.id)}>
                            {city.title}
                        </Button>
                    )
                }
            </Button.Group>
        </div >
    )
}

function KaspiProductList(props) {
    const merchantName = props.offer.merchantName
    const price = props.offer.price
    const deliveryType = props.offer.deliveryType
    const delivery = props.offer.delivery

    let deliveryDate = new Date(delivery).toLocaleString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });;

    return (


        <Table.Body className="divide-y">
            <Table.Row className=" dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className={`whitespace-nowrap font-medium text-gray-900 dark:text-white ${merchantName === 'NEMO-KZ' && 'text-blue-700 font-medium'}`}>
                    {merchantName}
                </Table.Cell>
                <Table.Cell>
                    {price.toLocaleString()}
                </Table.Cell>
                <Table.Cell>
                    {deliveryDate} {deliveryType}
                </Table.Cell>
            </Table.Row>
        </Table.Body>

    )
}




export default ProductKaspiModal
