import React, { useState } from 'react'
import { Navigate, Link } from 'react-router-dom'
import { authorization } from '../services/AuthService'
import { useDispatch, useSelector } from 'react-redux'



const LoginForm = () => {
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")

    const dispatch = useDispatch()

    const isAuth = useSelector(state => state.auth.isAuth)
    if (isAuth === 'authed') {
        return <Navigate to='/' />
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            dispatch(authorization(login, password))
        }
    }


    return (
        <section className="">
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <img
                            src={require('../assets/img/logo.png')}
                            className="mx-auto h-12 w-auto"
                            alt="Nemo Logo"
                        />
                        <h2 className="mt-6 text-center xs:text-lg text-3xl lg:text-3xl font-bold tracking-tight text-gray-900">Войдите в аккаунт</h2>
                        <p className=" mt-2 text-center text-sm text-gray-600 font-medium xs:text-xs lg:text-xl ">Сервис мониторинга товаров</p>

                    </div>
                    <div className="mt-8 space-y-8">
                        <input type="hidden" name="remember" value="true" />
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div className="flex items-center p-2 border rounded-md mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 w-7 h-7" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                </svg>
                                <input
                                    onChange={e => setLogin(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    type="login"
                                    placeholder="Логин"
                                    id="login"
                                    className="w-full p-1 ml-3 text-gray-500 border-none outline-none bg-transparent"
                                />
                            </div>
                            <div className="flex items-center p-2 border rounded-md ">
                                <svg width="15" height="15" fill="currentColor" className="text-gray-400 w-7 h-7" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z">
                                    </path>
                                </svg>
                                <input
                                    onChange={e => setPassword(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    type="password"
                                    placeholder="Пароль"
                                    id="password"
                                    className="w-full p-1 ml-3 text-gray-500 border-none outline-none bg-transparent"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                onClick={() => dispatch(authorization(login, password))}
                                onKeyDown={handleKeyDown}
                                className="group xs:font-normal xs:text-sm lg:text-base relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="8.57881" cy="6.27803" r="4.77803" fill="#F8F8F8" stroke="#F8F8F8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.00002 16.7016C0.998732 16.3657 1.07385 16.0339 1.2197 15.7313C1.67736 14.816 2.96798 14.3308 4.03892 14.1112C4.81128 13.9463 5.59431 13.8362 6.38217 13.7816C7.84084 13.6535 9.30793 13.6535 10.7666 13.7816C11.5544 13.8369 12.3374 13.947 13.1099 14.1112C14.1808 14.3308 15.4714 14.7702 15.9291 15.7313C16.2224 16.3481 16.2224 17.0642 15.9291 17.681C15.4714 18.6421 14.1808 19.0814 13.1099 19.2919C12.3384 19.4636 11.5551 19.5768 10.7666 19.6306C9.57937 19.7313 8.38659 19.7496 7.19681 19.6855C6.92221 19.6855 6.65677 19.6855 6.38217 19.6306C5.59663 19.5775 4.81632 19.4642 4.04807 19.2919C2.96798 19.0814 1.68652 18.6421 1.2197 17.681C1.0746 17.3749 0.999552 17.0403 1.00002 16.7016Z" fill="#F8F8F8" stroke="#F8F8F8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                Войти
                            </button>
                        </div>
                    </div>

                </div>

            </div>


        </section>
    )
}

export default LoginForm