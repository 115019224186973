import axios from 'axios'
import { PRODUCT_LIST_URL, PRODUCT_DETAIL_URL, DETAIL_SETTING, SYNC_DATA, DOWNLOAD_STOCKS } from '../constants/constants'
import { logOut } from '../redux/authSlice'
import { setDetailSetting, setFirstPosition } from '../redux/productConfigSlice'
import { setDetailProduct, setProducts } from '../redux/productSlice'
import { setToast } from '../redux/toastSlice'


export function updateInterval(props) {
	const url = 'https://dev.nemo-monitoring.kz/api/manage/v1/interval-update/?interval=' + props
	return fetch(url)
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.catch(error => {
			console.error('There was a problem with the fetch operation:', error);
		});
}
	
export function getInterval() {
	const url = 'https://dev.nemo-monitoring.kz/api/manage/v1/interval-update/'
	return fetch(url)
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.catch(error => {
			console.error('There was a problem with the fetch operation:', error);
		});
}

export function checkLimit() {
	const url = 'https://dev.nemo-monitoring.kz/api/service/v1/getLimit/';

	return fetch(url)
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.catch(error => {
			console.error('There was a problem with the fetch operation:', error);
		});
}


export function checkParsing() {
	const url = 'https://dev.nemo-monitoring.kz/api/service/v1/checkParse/';

	return fetch(url)
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.catch(error => {
			console.error('There was a problem with the fetch operation:', error);
		});
}

export function fetchProducts(props, pages) {
	let firstPrice = props.firstPrice
	let title = props.title
	// const page = props.page
	let page = pages ? pages.pages : 1
	let forKaspi = props.forKaspi
	let isActiveBot = props.isActiveBot
	let manager = props.manager
	let stockDaysMin = props.stockDaysMin
	let stockDaysMax = props.stockDaysMax

	let ordersWaitDaysMin = props.orderWaitDaysMin
	let ordersWaitDaysMax = props.orderWaitDaysMax

	let hideFirstPositions = props.hideFirstPositions
	let productWhithoutVideo = props.productWhithoutVideo
	let productWhithoutReviews = props.productWhithoutReviews
	let rating = props.rating
	let promotional = props.promotional
	let size = props.size
	let vip = props.vip
	let superBot = props.superBot

	let botLogLowThreshold = props.botLogLowThreshold
	let botLogLowPrice = props.botLogLowPrice

	let hasImgReview = props.hasImgReview


	if (forKaspi === 'Да') {
		forKaspi = true
	} else if (forKaspi === 'Нет') {
		forKaspi = false
	} else {
		forKaspi = null
	}

	if (isActiveBot === 'Да') {
		isActiveBot = true
	} else if (isActiveBot === 'Нет') {
		isActiveBot = false
	} else {
		isActiveBot = null
	}

	if (title === undefined) {
		title = ''
	} else {
		title = title.replace('+', '%2b')
	}

	if (manager === 'Менеджер' | !manager) {
		manager = ''
	}

	if (!hideFirstPositions) {
		hideFirstPositions = ''
	}

	if (!productWhithoutVideo) {
		productWhithoutVideo = ''
	}

	if (!productWhithoutReviews) {
		productWhithoutReviews = ''
	}

	if (!rating) {
		rating = false
	} else {
		rating = '3'
	}

	if (!promotional) {
		promotional = ''
	}

	if (!vip) {
		vip = ''
	}

	if (!size) {
		size = ''
	}

	if (!superBot) {
		superBot = ''
	}

	if (!botLogLowThreshold) {
		botLogLowThreshold = ''
	}
	
	if (!botLogLowPrice) {
		botLogLowPrice = ''
	}

	if (!hasImgReview) {
		hasImgReview = ''
	
	}

	let stocksDaysMin = `&stock_days_min=${stockDaysMin}`
	let stocksDaysMax = `&stock_days_max=${stockDaysMax}`

	let _orderWaitDaysMin = `&order_wait_min=${ordersWaitDaysMin}`
	let _orderWaitDaysMax = `&order_wait_max=${ordersWaitDaysMax}`

	console.log(ordersWaitDaysMin, ordersWaitDaysMax)
	console.log(_orderWaitDaysMin, _orderWaitDaysMax)
	const params = `size=${firstPrice}&title=${title}&page=${page}&for_kaspi=${forKaspi}&is_active_kaspi_bot=${isActiveBot}&manager__first_name=${manager}${stockDaysMin ? stocksDaysMin : ''}${stockDaysMax ? stocksDaysMax : ''}${rating ? '&kaspi_info__rating=3' : ''}${hideFirstPositions ? '&exclude_first_position=true' : ''}${productWhithoutReviews ? '&kaspi_info__reviews=' + productWhithoutReviews : ''}${productWhithoutVideo ? '&kaspi_info__has_video=false' : ''}
	${promotional ? '&promotional=true' : ''}${size ? '&size=true' : ''}${vip ? '&vip=true' : ''}${superBot ? '&super_bot=true' : ''}${botLogLowThreshold ? '&kaspi_bot_config_log_threshold=true' : ''}${botLogLowPrice ? '&kaspi_bot_config_log_low_price=true' : ''}${hasImgReview ? '&kaspi_info__has_img_review=false' : ''}${ordersWaitDaysMin ? _orderWaitDaysMin : ''}${ordersWaitDaysMax ? _orderWaitDaysMax : ''}`

	
	return async dispatch => {
		try {
			let access_token = localStorage.getItem('token')
			const response = await axios.get(PRODUCT_LIST_URL(params), {
				headers: {
					'Authorization': `Bearer ${access_token}`
				}
			})

			dispatch(setProducts(response.data))
			dispatch(setFirstPosition(response.data.first_pos))
		} catch (e) {
			localStorage.clear('token')
			dispatch(logOut())
		}
	}
}


export function patchProduct(props) {
	const code = props.code
	const data = props

	console.log(data)
	return async dispatch => {
		try {
			const response = await axios.patch(PRODUCT_DETAIL_URL(code), data)

			return response
		} catch (e) {
			dispatch(setToast(
				{
					'status': 'bad',
					'text': 'Ошибка! Данные не изменены',
					'duration': 1000,
					'show': true
				}
			))
			console.log(e)
			return 0
		}
	}
}

export const getDeatailProduct = (code) => {
	return async dispatch => {
		try {
			const response = await axios.get(PRODUCT_DETAIL_URL(code))
			dispatch(setDetailProduct(response.data[0]))
		} catch (e) {
			console.log(e)
			dispatch(setDetailProduct('Error'))
		}
	}
}


export const getDeatailSetting = () => {
	return async dispatch => {
		try {
			const response = await axios.post(DETAIL_SETTING())
			dispatch(setDetailSetting(response.data))
		} catch (e) {
			console.log(e)
			dispatch(setDetailSetting(false))
		}
	}
}


export const syncProductData = (value) => {
	let method
	if (value.value == 'Sync with 1C') {
		method = 'SyncData'
	} else if (value.value == 'Parse reviews') {
		method = 'ParseReviews'
	} else if (value.value == 'Parse kaspi') {
		method = 'ParseKaspi'
	}
	console.log(value.value)
	return async dispatch => {
		try {
			const response = await axios.post(SYNC_DATA(), {
				variant: method
			})
		} catch (e) {
			console.log(e)
		}
	}
}

export const downloadStockFile = () => {
	return async dispatch => {
		try {
			const response = axios({
				url: DOWNLOAD_STOCKS(),
				method: 'GET',
				responseType: 'blob',
			}).then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'stocks.xlsx');
				document.body.appendChild(link);
				link.click();
			}).catch(error => console.log(error));
		} catch (e) {
			console.log(e)
		}
	}
}