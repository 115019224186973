import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table, Badge, TextInput, ToggleSwitch, Button, Pagination, Tooltip, Checkbox } from 'flowbite-react'
import { HiAdjustments, HiCheckCircle, HiMinusCircle, HiOutlineVideoCamera, HiOutlinePhotograph, HiOutlineStar, HiOutlinePencilAlt, HiOutlineViewGrid, HiOutlineCamera } from "react-icons/hi";
import { setCleanData, setProductDetail, setProducts, setShowConfigModal, setShowKaspiModal, setShowStockModal, setStockData } from '../redux/productSlice'
import ProductModal from './ProductModal';
import { setToast } from '../redux/toastSlice';
import ProductKaspiModal from './FPProductKaspiModal';
import { fetchProducts, getDeatailProduct, patchProduct } from '../services/ProductAPI';
import { useSearchParams } from 'react-router-dom';
import { setProductPageConfig } from '../redux/productConfigSlice';
import { get_tarifs, get_delivery_cost } from '../services/calculateTaris'
import StockModal from './StockModal';
import { SaleGraphicModal } from './SaleGraphic';
import Seasonality from './Seasonality';


function ProductsTable() {
    let columnsValue = localStorage.getItem("showColumns");
    if (columnsValue == undefined) {
        columnsValue = '1'
    }
    // const [showColumns, setShowCoumns] = useState(columnsValue)

    const cleanData = useSelector(state => state.product.cleanData)
    const products = useSelector(state => state.product.products)
    const productConfig = useSelector(state => ({ ...state.productConfig, firstPrice: 'true' }));

    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams();
    const showConfigModal = useSelector(state => state.product.showConfigModal)
    const showKaspiModal = useSelector(state => state.product.showKaspiModal)
    const showStockModal = useSelector(state => state.product.showStockModal)
    let stdAverage, rsAverage, bonusAverage, totalSum, totalStock, totalReserv, totalQuanity, sales_count_yestarday, sales_count_day, sales_count, avgRating
    const params = {};
    searchParams.forEach((value, key) => {
        params[key] = value;
    });
    console.log(productConfig)
    useEffect(() => {
        
        if (cleanData) {
            setProducts('loading')
            setCleanData(false)
        }
        dispatch(
            setProducts('loading')
        )
        if (params.page) {
            dispatch(
                fetchProducts(productConfig, { pages: params.page})
            )
        } else {
            dispatch(
                fetchProducts(productConfig)
            )
        }
    }, [dispatch])

    const changeProductPage = (page) => {
        setSearchParams({ page: page })
        dispatch(
            setProductPageConfig(page)
        )
        dispatch(fetchProducts(productConfig, { pages: page }))
    }
    if (cleanData) {
        setCleanData(false)
        setProducts('loading')
    }
    if (products != 'loading' & products != undefined & products != null) {
        [stdAverage, rsAverage, bonusAverage, totalSum, totalStock, totalReserv, totalQuanity, sales_count_yestarday, sales_count_day, sales_count, avgRating] = totalAmount(products.results)
    }

    const updateProductFromModal = () => {
        dispatch(fetchProducts(productConfig, params.page && { pages: params.page }))
    }

    return (
        <>
            {showConfigModal && <ProductModal show={true} />}
            {/* {showKaspiModal && <ProductKaspiModal show={true} updProduct={updateProductFromModal}  /> } */}
            {showStockModal && <StockModal show={true} />}
            <Table
                hoverable={true}
                striped={true}
            >
                <Table.Head>
                    <Table.HeadCell className='!px-5'>
                        SKU
                    </Table.HeadCell>
                    <Table.HeadCell className='!pr-3 !pl-1'>
                        <div className={columnsValue == '1' ? 'w-72' : 'w-40'}>
                            Название
                        </div>

                    </Table.HeadCell>
                    <Table.HeadCell className=''>
                        {/* Сезонность */}
                    </Table.HeadCell>
                    <Table.HeadCell className='!px-3'>
                        VIP
                    </Table.HeadCell>
                    <Table.HeadCell className='!px-2'>
                        Promo
                    </Table.HeadCell>
                    <Table.HeadCell className='!px-3'>
                        FirstPrice
                    </Table.HeadCell>
                    <Table.HeadCell className='!px-3'>
                        Фото, отзывы
                    </Table.HeadCell>
                    <Table.HeadCell className={'!px-5 ' + (columnsValue == '1' ? '' : 'hidden')} >
                        Остаток
                    </Table.HeadCell>
                    <Table.HeadCell className={columnsValue == '1' ? '' : 'hidden'}>
                        Резерв
                    </Table.HeadCell>
                    <Table.HeadCell className='!px-5'>
                        Доступно
                    </Table.HeadCell>
                    <Table.HeadCell className='!px-5'>
                        Ожидаем от поставщика
                    </Table.HeadCell>
                    <Table.HeadCell className={'!px-5 ' + (columnsValue == '1' ? '' : 'hidden')} >
                        Дней запаса
                    </Table.HeadCell>
                    <Table.HeadCell className={'!px-5 ' + (columnsValue == '1' ? '' : 'hidden')} >
                        Дней на складе
                    </Table.HeadCell>
                    <Table.HeadCell className={columnsValue == '1' ? '' : 'hidden'} >
                        Сумм.Себес
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Себес
                    </Table.HeadCell>
                    <Table.HeadCell className=''>
                        Розница
                    </Table.HeadCell>
                    {/* <Table.HeadCell className='!px-3 '>
                        ST
                    </Table.HeadCell> */}
                    <Table.HeadCell className='!px-3'>
                        RS
                    </Table.HeadCell>
                    <Table.HeadCell className={'!px-3 ' + (columnsValue == '1' ? '' : 'hidden')}>
                        MG
                    </Table.HeadCell>
                    <Table.HeadCell>
                        За вчера
                    </Table.HeadCell>
                    <Table.HeadCell>
                        За сегодня
                    </Table.HeadCell>
                    <Table.HeadCell>
                        За 30 дней
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Позиция
                    </Table.HeadCell>
                    <Table.HeadCell className='!px-5'>
                        Настройки
                    </Table.HeadCell>
                    <Table.HeadCell className='!px-5'>
                        Ссылки
                    </Table.HeadCell>
                    <Table.HeadCell className='!px-5'>
                        Лог
                    </Table.HeadCell>
                </Table.Head>

                <Table.Body className="divide-y">
                    {products === 'loading' ? [] :
                        products['results'].map((product, index) =>
                            <ProductList
                                key={index}
                                product={product}
                            />
                        )}
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell></Table.Cell><Table.Cell></Table.Cell><Table.Cell></Table.Cell><Table.Cell></Table.Cell><Table.Cell></Table.Cell><Table.Cell></Table.Cell><Table.Cell className='text-center'>{avgRating && avgRating.toLocaleString('ru')}</Table.Cell>
                        <Table.Cell className='text-center'>{totalStock && totalStock.toLocaleString('ru')}</Table.Cell><Table.Cell className='text-center'>{totalReserv && totalReserv.toLocaleString('ru')}</Table.Cell><Table.Cell className='text-center'>{totalQuanity && totalQuanity.toLocaleString('ru')}</Table.Cell>
                        <Table.Cell></Table.Cell><Table.Cell></Table.Cell> <Table.Cell></Table.Cell>
                        <Table.Cell>{totalSum && totalSum.toLocaleString('ru')}</Table.Cell><Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        {/* <Table.Cell>{stdAverage}</Table.Cell> */}
                        <Table.Cell>{rsAverage}</Table.Cell><Table.Cell>{bonusAverage}</Table.Cell>
                        <Table.Cell className='text-center'>{sales_count_yestarday && sales_count_yestarday.toLocaleString('ru')}</Table.Cell>
                        <Table.Cell className='text-center'>{sales_count_day && sales_count_day.toLocaleString('ru')}</Table.Cell>
                        <Table.Cell className='text-center'>{sales_count && sales_count.toLocaleString('ru')}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <div className="flex items-center justify-center text-center pt-8 pb-4">
                <Pagination
                    currentPage={products === 'loading' ? 1 : products['current']}
                    layout="pagination"
                    onPageChange={page => changeProductPage(page)}
                    showIcons={true}
                    totalPages={products === 'loading' ? 1 : products['total_pages']}
                />
            </div>
        </>

    )
}

const totalAmount = (products) => {
    let stdAverage = 0
    let rsAverage = 0
    let bonusAverage = 0
    let totalSum = 0
    let totalStock = 0
    let totalReserv = 0
    let totalQuanity = 0

    let sales_count_yestarday = 0
    let sales_count_day = 0
    let sales_count = 0
    let totalRatingAndRev = 0
    let total_comments = 0
    let avgRating = 0
    if (products) {
        products.forEach((product, key) => {
            let pp = getTotalTatif(product)
            if (pp) {

                
                stdAverage = stdAverage + (parseFloat(pp[0]) * parseFloat(pp[3]))
                rsAverage = rsAverage + (pp[1] * pp[3])
                bonusAverage = bonusAverage + (pp[2] * pp[3])
                totalSum += pp[3] 
                totalStock += pp[4]
                totalReserv += pp[5]
                totalQuanity += pp[6]
            }

            if (product.sales) {
                sales_count_yestarday += product.sales?.sales_count_yestarday
                sales_count_day += product.sales?.sales_count_day
                sales_count += product.sales?.sales_count
            }
            if (product.kaspi_info) {
                // totalRating += product.kaspi_info?.rating
                totalRatingAndRev += product.kaspi_info?.rating * product.kaspi_info?.reviews
                total_comments += product.kaspi_info?.reviews
            }
        });
    }

    stdAverage = (stdAverage / totalSum).toFixed(2)
    rsAverage = (rsAverage / totalSum).toFixed(2)
    bonusAverage = (bonusAverage / totalSum).toFixed(2)
    avgRating = (totalRatingAndRev / total_comments).toFixed(2)
    console.log(stdAverage, rsAverage, bonusAverage, totalSum, totalStock, totalReserv, totalQuanity, sales_count_yestarday, sales_count_day, sales_count, avgRating)
    return [stdAverage, rsAverage, bonusAverage, totalSum, totalStock, totalReserv, totalQuanity, sales_count_yestarday, sales_count_day, sales_count, avgRating]
}

const getTotalTatif = (product) => {
    let costPrice = 0
    let costScope = []
    let stock = 0
    let reserve = 0
    let quanity = 0
    let costSum = 0
    let stockDaysScope = []

    const weight = product?.weight
    const stdCommission = product?.category.std
    const rsCommission = product?.category.rs
    const bonusCommission = product?.category.bonus

    const word = 'Сервис'

    if (Object.keys(product?.stocks).length > 0) {
        for (let stocks of product.stocks) {
            if (product.stocks) {

            
            if (stocks.title.includes(word)) {
                continue
            } else {
                // console.log('Строка не содержит слово "Сервис"');
                
            if (stocks['stock'] != 0) {
                stock = stocks['stock'] + stock

            }
            if (stocks['on_a_way'] != 0) {
                stock = stocks['on_a_way'] + stock

            }

            if (stocks['reserve'] != 0) {
                reserve = stocks['reserve'] + reserve
            }
            if (stocks['quanity'] != 0) {
                quanity = stocks['quanity'] + quanity
            }

            costSum = stocks['cost_sum'] + costSum
            if (stocks['cost_price'] != 0) {
                costScope.push(stocks['cost_price'])
            }
            if (stocks['stock'] != 0) {
                stockDaysScope.push(stocks['stock_days'])
            }
        }
        }
    }
    }
    var sum = 0;
    for (var i = 0; i < costScope.length; i++) {
        sum += costScope[i];
    }
    costPrice = parseFloat((sum / costScope.length).toFixed())
    if (costPrice) {
        const productPrice = product['prices']['almaty_price']
        let delivery = get_delivery_cost(parseInt(productPrice), weight)
        let [STD, RS, BONUS] = get_tarifs(productPrice, parseInt(costPrice), delivery, stdCommission, rsCommission, bonusCommission)
        return (
            [STD, RS, BONUS, costSum, stock, reserve, quanity]
        )
    } 
}

const ProductList = (props) => {
    let columnsValue = localStorage.getItem("showColumns");
    if (columnsValue == undefined) {
        columnsValue = '1'
    }

    let product = props.product
    // const [product, setProduct] = useState(props.product)
    const code = product['code']
    const title = product['title']

    let stock = 0
    let reserve = 0
    let quanity = 0
    let costPrice = 0
    let costSum = 0
    let stockDays = 0
    let reserveDays = '-'
    let salesCount = 0
    let costScope = []
    let stockDaysScope = []
    let orderWait = 0

    let stockMultiplyStockDays = 0

    if (Object.keys(product?.stocks).length > 0) {
        const word = 'Сервис';
        for (let stocks of product['stocks']) {

            if (stocks.title.includes(word)) {
                // console.log('Строка содержит слово "Сервис"');
                continue
            } else {
                // console.log('Строка не содержит слово "Сервис"');


                stock = stocks['stock'] + stock
                reserve = stocks['reserve'] + reserve
                quanity = stocks['quanity'] + quanity

                // stockDays = stocks['stock_days'] + stockDays
                // costPrice = stocks['cost_price']
                costSum = stocks['cost_sum'] + costSum
                if (stocks['cost_price'] != 0) {
                    costScope.push(stocks['cost_price'])
                }
                if (stocks['stock'] != 0) {
                    stockDaysScope.push(stocks['stock_days'])
                }
                if (stocks['order_wait'] != 0) {
                    orderWait = stocks['order_wait'] + orderWait
                }

                if (stocks['on_a_way'] != 0) {
                    stock = stock + stocks['on_a_way']
                }
                stockMultiplyStockDays = stockMultiplyStockDays + (stocks['stock'] * parseInt(stocks['stock_days']))
            }
        }
    }


    var sum = 0;
    for (var i = 0; i < costScope.length; i++) {
        sum += costScope[i];
    }
    costPrice = parseFloat((sum / costScope.length).toFixed())


    var sumStockDays = 0;
    for (var i = 0; i < stockDaysScope.length; i++) {
        sumStockDays += stockDaysScope[i];
    }
    // console.table(stockDaysScope)
    // console.table(stock)
    const stockDaysSum = stockDaysScope.reduce((acc, num) => acc + num, 0);
    stockDays = stockDaysSum / stockDaysScope.length;

    // stockDays = parseFloat((stockMultiplyStockDays / stock).toFixed()) // stockMultiplyStockDays / stock // parseFloat((sumStockDays / stockDaysScope.length).toFixed())


    if (product.sales && Object.keys(product.sales).length > 0) {
        salesCount = product.sales.sales_count
        reserveDays = (stock / (salesCount / 30)).toFixed()
    }

    const vip = product['vip']
    const size = product['size']
    const turboBot = product['super_bot']
    const promotional = product['promotional']
    const seasonality = product['seasonality']
    let productPrice = product['prices']['price_fp']
    const isActiveBot = product['is_active_kaspi_bot']
    const kaspiLink = product['kaspi_link']
    const weight = product.weight
    const stdCommission = product.category.std
    const rsCommission = product.category.rs
    const bonusCommission = product.category.bonus
    const kaspiOfferLog = product.kaspi_bot_config && product.kaspi_bot_config.kaspi_offer_log.almaty

    let kaspiPosition = null
    const kaspiPriceLists = product['price_lists']
    let formattedDate = null
    for (const value of kaspiPriceLists) {
        if (value['code'] === '750000000') {
            kaspiPosition = value['position']
            const date = new Date(value['updated_at']);
            const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

            formattedDate = date.toLocaleString('ru-RU', options);
        }
    }
    const [botValue, setBotValue] = useState(isActiveBot)

    const [salesModal, setSalesModal] = useState(false)

    const [vipValue, setVipValue] = useState(vip)
    const [turboBotValue, setTurboBotValue] = useState(turboBot)
    const [sizeValue, setSizeValue] = useState(size)
    const [promotionalValue, setPromotionalValue] = useState(promotional)

    const [price, setPrice] = useState(productPrice)

    const [priceChangeState, setPriceChangeState] = useState('gray')

    const [modal, setShowModal] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (productPrice) {
            setPrice(productPrice)
        } else {
            setPrice('')
        }

        setBotValue(isActiveBot)
        setTurboBotValue(turboBot)
        setVipValue(vip)
        setSizeValue(size)
        setPromotionalValue(promotional)
    }, [productPrice, vip, size, promotional])

    const showModal = () => {
        dispatch(setShowConfigModal(true))
        dispatch(
            getDeatailProduct(code)
        )
    }

    const updateBot = (value) => {
        dispatch(
            patchProduct({ 'code': code, 'is_active_kaspi_bot': value })
        ).then(response => {
            if (response.status === 200) {
                setBotValue(value)
                dispatch(setToast(
                    {
                        'status': 'success',
                        'text': 'Настройки бота изменены',
                        'duration': 1000,
                        'show': true
                    }
                ))
            } else {
                dispatch(setToast(
                    {
                        'status': 'bad',
                        'text': 'Ошибка! Данные не изменены',
                        'duration': 1000,
                        'show': true
                    }
                ))
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const openSalesModal = (value) => {
        setSalesModal(value)
    }

    const openKaspiModal = () => {
        dispatch(setProductDetail({
            'code': code,
            'kaspiLink': kaspiLink,
            'prices': product['prices']
        }))
        setShowModal(true)
        // dispatch(setShowKaspiModal(true))

    }

    let imageCount = '-'
    let hasVideo = false
    let reviews = '-'
    let hasPromo = false
    let rating = '-'
    let kaspi_place = '-'
    let has_img_review = null

    if (product.kaspi_info) {
        imageCount = product.kaspi_info.images
        hasVideo = product.kaspi_info.has_video
        reviews = product.kaspi_info.reviews
        hasPromo = product.kaspi_info.has_promo
        rating = product.kaspi_info.rating
        kaspi_place = product.kaspi_info.kaspi_place
        has_img_review = product.kaspi_info.has_img_review
    }

    const updateProduct = (value, filedName) => {
        dispatch(
            patchProduct({ 'code': code, [filedName]: value })
        ).then(response => {
            if (response.status === 200) {
                if (filedName == 'vip') {
                    setVipValue(value)
                } else if (filedName == 'size') {
                    setSizeValue(value)
                } else if (filedName == 'super_bot') {
                    setTurboBotValue(value)
                } else {
                    setPromotionalValue(value)
                }

                dispatch(setToast(
                    {
                        'status': 'success',
                        'text': 'Настройки продукта изменены',
                        'duration': 1000,
                        'show': true
                    }
                ))
            } else {
                dispatch(setToast(
                    {
                        'status': 'bad',
                        'text': 'Ошибка! Данные не изменены',
                        'duration': 1000,
                        'show': true
                    }
                ))
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const changeProductProps = (propsName, val) => {
        updateProduct(val, propsName)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' | event.key === '229') {
            // setPrice(price);
            // const formatedPrice = parseInt(price.replace(/\s/g, ''));
            // console.log(parseInt(price))
            const priceData = {
                "code": code,
                "prices": {
                    "price_fp": parseInt(parseInt(price.toString().replace(/\s/g, ''))),
                }
            }
            dispatch(patchProduct(priceData))
            // changePrice(parseInt(price), parseInt(price), parseInt(price))
            dispatch(setToast(
                {
                    'status': 'success',
                    'text': 'Цена успешно изменена',
                    'duration': 2000,
                    'show': true
                }
            ))
            setPriceChangeState('success')
        }
    }
    let delivery = get_delivery_cost(parseInt(price?.toString().replace(/\s/g, '')), weight)

    let [STD, RS, BONUS] = get_tarifs(parseInt(price?.toString().replace(/\s/g, '')), parseInt(costPrice), delivery, stdCommission, rsCommission, bonusCommission)

    const changePriceState = (val) => {
        delivery = get_delivery_cost(parseInt(val.toString().replace(/\s/g, '')), weight)
        let [STD, RS, BONUS] = get_tarifs(parseInt(val.toString().replace(/\s/g, '')), parseInt(costPrice), delivery, stdCommission, rsCommission, bonusCommission)
        // setPrice(parseInt(val.replace(/\s/g, '')))
        setPrice(val)
    }

    // const changePrice = useMemo(() => {
    //     return (newAlmaty, newAstana, newRegions) => {
    //       product.prices.almaty_price = newAlmaty;
    //       product.prices.astana_price = newAstana;
    //       product.prices.region_price = newRegions;
    //     }
    //   }, [product.prices]);

    const changePrice = (newFirstPrice) => {
        // const updatedProduct = { ...product }; // Создаем новый объект с копиями свойств
        product.prices = {
            ...product.prices,
            firstprice: newFirstPrice
        }
        // product = updatedProduct
        // setProduct(updatedProduct); // Обновляем состояние объекта product

    };


    const tarifColor = (tarif) => {
        let color
        if (tarif >= 3) {
            color = 'text-green-400'
        } else if (tarif < 3) {
            color = 'text-orange-400'
        } else if (tarif < 0) {
            color = 'text-red-500'
        }
        return color
    }
    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            {modal && <ProductKaspiModal detail={{
                'code': code,
                'kaspiLink': kaspiLink,
                'prices': product.prices
            }} show={true} closeModal={setShowModal} setAlmatyPrice={changePrice} />}
            {salesModal && <SaleGraphicModal salesQuanity={product.sales?.sales_count} code={code} openSalesModal={openSalesModal} />}

            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {/* <div className='absolute inline-flex left-2 -mt-4 bg-red-400 w-2 h-2 rounded-md'></div> */}
                <div className='z-50'>{code}</div></Table.Cell>
            <Table.Cell className='!pr-3 !pl-1'>{title}</Table.Cell>


            <Table.Cell className='!px-3 text-center mx-auto'>
                {/* <div className="flex items-center gap-2 mx-auto">
                <Seasonality seasonality={seasonality} code={code} />
                </div> */}
            </Table.Cell>


            <Table.Cell className='!px-3 text-center'>
                <div className="flex items-center gap-2">
                    <Checkbox id="vip" checked={vipValue} defaultChecked={vipValue} onChange={e => changeProductProps('vip', e.target.checked)} />
                </div>
            </Table.Cell>
            <Table.Cell className='!px-3 text-center'>
                <div className="flex items-center gap-2">
                    <Checkbox id="promotional" checked={promotionalValue} defaultChecked={promotionalValue} onChange={e => changeProductProps('promotional', e.target.checked)} />
                </div>
            </Table.Cell>
            <Table.Cell className='!px-3 text-center'>
                <div className="flex items-center gap-2">
                    <Checkbox id="size" checked={sizeValue} defaultChecked={sizeValue} onChange={e => changeProductProps('size', e.target.checked)} />
                </div>
            </Table.Cell>
            <Table.Cell className='!px-3'><div className='w-28 grid grid-cols-2 text-center'>
                <div className='gap-2'>
                    <Tooltip content="Наличие видео в Kaspi" style="light">
                        <p className='flex items-center gap-1'><HiOutlineVideoCamera />: {hasVideo ? <HiCheckCircle className='text-green-500' /> : <HiMinusCircle className='text-red-500' />} </p>
                    </Tooltip>
                    <Tooltip content="Количество фото в Kaspi" style="light">
                        <p className='flex items-center gap-1'><HiOutlinePhotograph />: {imageCount}</p>
                    </Tooltip>
                </div>

                <div className='gap-2'>
                    <Tooltip content="Рейтинг в Kaspi" style="light">
                        <p className={`${rating < 4.25 ? 'text-red-500' : rating < 4.75 ? 'text-yellow-500' : ''} flex items-center gap-1`}> <HiOutlineStar />: {rating}</p>
                    </Tooltip>
                    <Tooltip content="Количество отзывов в Kaspi" style="light">
                        <p className={`${reviews < 5 && 'text-red-500'} flex items-center gap-1`}> <HiOutlinePencilAlt />: {reviews}</p>
                    </Tooltip>
                </div>
                <div className='gap-2'>
                    <Tooltip content="Страница в категории" style="light">
                        <p className='flex items-center gap-1'> <HiOutlineViewGrid />: {kaspi_place ? kaspi_place : '-'}</p>
                    </Tooltip>
                </div>
                <div className='gap-2'>
                    <Tooltip content="Наличие фото в отзывах" style="light">
                        <p className='flex items-center gap-1'><HiOutlineCamera />: {has_img_review ? <HiCheckCircle className='text-green-500' /> : <HiMinusCircle className='text-red-500' />} </p>
                    </Tooltip>
                </div>
                
            </div></Table.Cell>
            <Table.Cell className={'text-center cursor-pointer ' + (columnsValue == '1' ? '' : 'hidden')} onClick={() => dispatch(setShowStockModal(true)) & dispatch(setStockData(product['stocks']))}>{stock}</Table.Cell>
            <Table.Cell className={'text-center cursor-pointer ' + (columnsValue == '1' ? '' : 'hidden')} onClick={() => dispatch(setShowStockModal(true)) & dispatch(setStockData(product['stocks']))}>{reserve}</Table.Cell>
            <Table.Cell className='text-center cursor-pointer' onClick={() => dispatch(setShowStockModal(true)) & dispatch(setStockData(product['stocks']))}>{quanity}</Table.Cell>
            <Table.Cell className='text-center'>{orderWait != 0 && orderWait}</Table.Cell>
            <Table.Cell className={'whitespace-nowrap' + (columnsValue == '1' ? '' : ' hidden')}>{reserveDays}</Table.Cell>
            <Table.Cell className={`text-center ${stockDays <= 30 ? 'text-green-400'
                : stockDays > 30 & stockDays < 60 ? 'text-yellow-300'
                    : stockDays >= 60 & stockDays < 100 ? 'text-orange-400'
                        : 'text-red-500'
                } ` + (columnsValue == '1' ? '' : ' hidden')}>{stockDays}</Table.Cell>
            <Table.Cell className={'whitespace-nowrap ' + (columnsValue == '1' ? '' : 'hidden')}>{costSum && costSum.toLocaleString('ru')}</Table.Cell>
            <Table.Cell className='whitespace-nowrap'><div>{costPrice && costPrice.toLocaleString('ru')}</div></Table.Cell>
            <Table.Cell className='whitespace-nowrap'><TextInput className='w-20'
                id="price"
                type="text"
                readOnly={false}
                color={priceChangeState}
                value={price && price.toLocaleString('ru')}
                onChange={value => changePriceState(value.target.value)}
                onKeyDown={handleKeyDown} /></Table.Cell>
            {/* <Table.Cell className={`!px-3 text-center ${tarifColor(STD)}`}>{isFinite(STD) ? STD : '-'} <br /><span className='text-xs text-center absolute text-gray-400'>{stdCommission}</span></Table.Cell> */}
            <Table.Cell className={`!px-3 text-center ${tarifColor(RS)}`}>{isFinite(RS) ? RS : '-'}<br /><span className='text-xs text-center absolute text-gray-400'>{rsCommission}</span></Table.Cell>
            <Table.Cell className={`!px-3 text-center ${columnsValue == '1' ? '' : ' hidden'} ${tarifColor(BONUS)}`}>{isFinite(BONUS) ? BONUS : '-'} <br /><span className='text-xs text-center absolute text-gray-400'>{bonusCommission}</span></Table.Cell>
            <Table.Cell className='text-center'>
                {product.sales?.sales_count_yestarday}
            </Table.Cell>
            <Table.Cell className='text-center'>
                {product.sales?.sales_count_day}
            </Table.Cell>
            <Table.Cell className='text-center cursor-pointer' onClick={e => openSalesModal(true)}>
                {product.sales?.sales_count}
            </Table.Cell>
            <Table.Cell><Badge
                onClick={openKaspiModal} className={`cursor-pointer whitespace-nowrap text-white dark:text-white !text-xs ${kaspiPosition === 1 ? 'bg-green-400' : 'bg-blue-400'}`}
                color=''
                size="sm">{kaspiPosition ? `${kaspiPosition} Место` : 'missing'}
            </Badge><div className='text-xs absolute mt-2 w-20 text-gray-500'>{formattedDate && formattedDate}</div></Table.Cell>
            <Table.Cell><div className='flex items-center'>
            <div className="flex items-center gap-2">
            <div className='pr-2 pb-0.5'> 
            <Checkbox
                id="turbo_bot"
                className={turboBotValue && '!bg-red-400'}
                checked={turboBotValue}
                defaultChecked={turboBotValue}
                onChange={e => changeProductProps('super_bot', e.target.checked) & setTurboBotValue(e.target.checked)} />
            </div>
                </div>
                <label className="inline-flex relative items-center cursor-pointer pr-2">
                    <input
                        checked={botValue}
                        onChange={value => botValue ? updateBot(false) : updateBot(true)}
                        type="checkbox" value="" className="sr-only peer" />
                    <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
                <Button
                    color="gray"
                    className='h-3 w-7'
                    onClick={showModal}>
                    <HiAdjustments className="mr-0 h-3 w-3" />
                    {' '}
                </Button>
            </div></Table.Cell>
            <Table.Cell><div className='text-xs'>
                <a href={kaspiLink} target={"_blank"} rel="noreferrer">
                    <Badge className={`whitespace-nowrap  text-white dark:text-white ${kaspiLink ? 'bg-red-500' : 'bg-red-200'}  !text-xs`}
                        color="red-500"
                        size="xs"
                    >Kaspi</Badge>
                </a>
            </div></Table.Cell>
            <Table.Cell className=''>
                <div className='w-50 whitespace-nowrap overflow-auto no-scrollbar'>
                    {kaspiOfferLog}
                </div>
            </Table.Cell>
        </Table.Row>
    )
}

export default ProductsTable

