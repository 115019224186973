import React, { useEffect, useState } from 'react';
import { Modal } from 'flowbite-react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';



function ChartModal({ id, isOpen, onClose}) {
  const [activeTab, setActiveTab] = useState('position');
  const [data, setData] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null);
  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        const result = await fetch(`https://dev.nemo-monitoring.kz/api/v1/place-checker/${id}`);
        const jsonData = await result.json();
        setData(jsonData.data);
        setTaskStatus(jsonData.task_status);
      };

      fetchData();

      const interval = setInterval(fetchData, 5000);

      return () => clearInterval(interval);
    }
    if (!isOpen) {
      setData(null);
    }
  }, [isOpen]);

  if (!data) {
    return null;  // Если данных нет, ничего не отображаем
  }

  const convertToAlmatyTimezone = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const dateObject = new Date(`${datePart}T${timePart}Z`); // Interpreting as UTC time
    const timezoneOffset = 6 * 60 * 60 * 1000; // 6 hours in milliseconds
    
    return new Date(dateObject.getTime() );
  }

  const chartData = Object.entries(data).filter(([key]) => key !== "summarize").map(([date, detail]) => ({
    x: convertToAlmatyTimezone(date).getTime(),
    y: Number(detail.price),
    name: detail.shop
  }));

  const positionData = Object.entries(data).filter(([key, detail]) => key !== "summarize").map(([date, detail]) => ({
    x: convertToAlmatyTimezone(date).getTime(),
    y: detail.place,
    shop: detail.shop,
    price: detail.price,
    color: detail.place === 1 ? 'lightgreen' : undefined
  }));


  const priceOptions = {
    title: {
      text: 'Изменение цены по времени'
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Время'
      }
    },
    yAxis: {
      title: {
        text: 'Цена'
      },
    },
    series: [{
      data: chartData,
      name: 'Цена товара',
      tooltip: {
        pointFormat: '{point.name}: <b>{point.y}</b>'
      }
    }]
  };

  const positionOptions = {
    title: {
      text: 'Позиция магазина NEMO-KZ по времени'
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Время'
      }
    },
    yAxis: {
      title: {
        text: 'Место'
      },
      reversed: true, // Чтобы первое место было наверху
      min: 1 // Если у вас минимальное место — 1
    },
    series: [{
      data: positionData,
      name: 'Место магазина NEMO-KZ',
      tooltip: {
        pointFormat: 'Место: <b>{point.y} - {point.shop} - {point.price}</b>'
      }
    }]
  };

  return (
    <Modal show={isOpen} onClose={onClose} title="Детали" size="7xl">
      <div className="flex mb-4">
        <button onClick={() => setActiveTab('position')} className={`px-4 py-2 ${activeTab === 'position' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>Позиция</button>
        <button onClick={() => setActiveTab('price')} className={`px-4 py-2 ${activeTab === 'price' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>Цена</button>
      </div>

      <Modal.Header>
       {taskStatus == 'active' ? 'В процессе. График автообновляется' : 'Проверка завершена'} 
      </Modal.Header>
      <Modal.Body>
        {activeTab === 'price' ? (
          <HighchartsReact highcharts={Highcharts} options={priceOptions} />
        ) : (
          <HighchartsReact highcharts={Highcharts} options={positionOptions} />
        )}

        <div className="mt-4">
          <h4 className="mb-2">Сводка:</h4>
          {Object.entries(data.summarize).map(([shop, timeInfo]) => (
            <p key={shop}><strong>{shop}:</strong> {timeInfo}</p>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ChartModal;
