import React, { useState, Fragment } from 'react'
import { Card, TextInput, Avatar, Button } from 'flowbite-react'
import { useDispatch } from 'react-redux'
import { setToast } from '../redux/toastSlice';
import axios from 'axios';



function CommentCard(props) {
    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
      }
    const [count, setCount] = useState(parseInt(getRandomArbitrary(5, 15)));

    const [error, setError] = useState(false);

    const dispatch = useDispatch()

    const startParse = (commentId) => {
        if (parseInt(count) > 100) {
            dispatch(setToast(
                {
                    'status': 'bad',
                    'text': 'Максимум 100 лайков',
                    'duration': 2000,
                    'show': true
                }
            ))
            setError(true)
            return
        }
        setError(false)
        const data = {
            "commentId": commentId,
            "count": count
        }
        let timeAbout = parseInt(count) * 2

        const apiUrl = `https://dev.nemo-monitoring.kz/api/v1/reviews/`;
        axios.post(apiUrl, data)
            .then(response => {
                dispatch(setToast(
                    {
                        'status': 'success',
                        'text': `Задание запущено, ориентировочное время ${timeAbout} секнуд'`,
                        'duration': 5000,
                        'show': true
                    }
                ))
            })
            .catch(error => {
                dispatch(setToast(
                    {
                        'status': 'success',
                        'text': 'ERROR! ...',
                        'duration': 2000,
                        'show': true
                    }
                ))
            });


    }
    const rating = () => {
        const stars = [];
        for (let i = 0; i < props.rating; i++) {
          stars.push(
            <svg key={i} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.27824 10.6735L12.0781 14.4449C12.1498 14.5012 12.2535 14.4888 12.3098 14.4171C12.3449 14.3725 12.3545 14.313 12.3354 14.2596L10.2482 8.42351L14.3791 5.34581C14.4522 5.29141 14.4673 5.18801 14.4128 5.11501C14.3817 5.07321 14.3326 5.04851 14.2805 5.04851H9.25814L7.43254 0.207909C7.40044 0.122609 7.30524 0.0796097 7.21994 0.11171C7.17554 0.12851 7.14054 0.163509 7.12384 0.207909L5.29814 5.04851H0.275841C0.184641 5.04851 0.11084 5.12241 0.11084 5.21351C0.11084 5.26571 0.135438 5.31471 0.177238 5.34591L4.30814 8.42351L2.22094 14.2596C2.19024 14.3454 2.23494 14.4399 2.32074 14.4705C2.37414 14.4896 2.43364 14.48 2.47824 14.4449L7.27824 10.6735Z" fill="#F14635" />
            </svg>
          );
        }
        return (
          <>
            {stars}
          </>
        );
      };
      
    console.log(rating())
    return (
        <div className="max-w-3xl mx-auto pt-2">
            <Card>
                <div className="flex items-center">
                    <h6 className="text-l font-bold leading-none text-gray-900 dark:text-white">
                        {props.name}
                    </h6>
                    <span className='pl-2 flex items-center text-sm'>
                        {rating()}

                    </span>
                </div>
                <div className="flow-root">
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                        <li className="py-3 sm:py-0">
                            <div className="flex items-center space-x-4">
                                <div className="shrink-0">
                                    <Avatar rounded={true} />
                                </div>
                                <div className="min-w-0 flex-1">
                                    <p className="text-sm font-medium text-gray-500 dark:text-white">
                                        {props.date}
                                    </p>
                                    {props.plus &&
                                        <p className="pt-2 text-sm text-gray-900 dark:text-gray-400">
                                            <span className=" font-semibold  text-sm text-gray-900 dark:text-gray-400">Достоинства: </span>
                                            {props.plus}
                                        </p>}

                                    {props.minus &&
                                        <p className="pt-2 text-sm text-gray-900 dark:text-gray-400">
                                            <span className=" font-semibold  text-sm text-gray-900 dark:text-gray-400">Недостатки: </span>
                                            {props.minus}
                                        </p>
                                    }

                                    {props.text &&
                                        <p className="pt-2 text-sm text-gray-900 dark:text-gray-400">
                                            <span className=" font-semibold  text-sm text-gray-900 dark:text-gray-400">Комментарий: </span>
                                            {props.text}
                                        </p>
                                    }
                                    <p className="pt-2 text-sm font-medium text-gray-500 dark:text-white">
                                        {props.like} из {props.like + props.dislike} человек посчитали отзыв полезным.
                                    </p>

                                </div>

                            </div>
                            <div className="w-full grid justify-items-end inline-flex text-base text-gray-900 dark:text-white">
                                <div className='flex'>
                                    <TextInput
                                        id="small"
                                        type="text"
                                        sizing="l"
                                        className='w-14 text-center mx-2'
                                        value={count}
                                        onChange={value => setCount(value.target.value)}
                                    />
                                    <Button color="gray" onClick={e => startParse(props.commentId)}>
                                        Накрутить
                                    </Button>
                                </div>
                                { error &&
                                <p className='text-red-600 font-medium text-sm'>Ограничение 100 лайков</p>
                            }
                            </div>
                        </li>
                    </ul>
                </div>
            </Card>
        </div>
    )
}

export default CommentCard