import { createSlice } from '@reduxjs/toolkit'


const defaultToastState = {
    status: 'success',
    text: '',
    duration: 2000,
    show: false
}

const ToastSlice = createSlice( {
    name: 'toast',
    initialState: defaultToastState,
    reducers: {
        setToast(state, action) {
            state.status = action.payload.status;
            state.text = action.payload.text;
            state.duration = action.payload.duration;
            state.show = action.payload.show;
        },
        setShowToast(state, action) {
            state.show = action.payload;
        },
    },
})

export default ToastSlice.reducer
export const {
    setToast,
    setShowToast,
 } = ToastSlice.actions