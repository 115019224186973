import React, { useState } from 'react'
import { Button, Modal, Table } from 'flowbite-react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowStockModal, setStockData } from '../redux/productSlice'


function StockModal(props) {
    const [show, setShow] = useState(props.show)
    const dispatch = useDispatch()

    const stockData = useSelector(state => state.product.stockData)

    const onClick = () => {
        setShow(false)
        dispatch(setShowStockModal(false))
        dispatch(setStockData([]))
    }
    return (
        <React.Fragment>
            <Modal
                show={show}
                onClose={onClick}
                size="4xl"
            >
                <Modal.Header>
                    Остатки
                </Modal.Header>
                <Modal.Body>
                    <Table striped={true}>
                        <Table.Head>
                            <Table.HeadCell>
                                Склад
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Остаток
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Резерв
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Доступно
                            </Table.HeadCell>
                            <Table.HeadCell>
                                В пути
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {stockData
                                .filter(stock => !stock.title.includes('Сервис'))
                                .map(stock => (
                                    <Table.Row key={stock.store_id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                            {stock.title}
                                        </Table.Cell>
                                        <Table.Cell>{stock.stock}</Table.Cell>
                                        <Table.Cell>{stock.reserve}</Table.Cell>
                                        <Table.Cell>{stock.quanity}</Table.Cell>
                                        <Table.Cell>{stock.on_a_way}</Table.Cell>
                                    </Table.Row>
                                ))}
                        </Table.Body>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onClick}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default StockModal