import axios from 'axios'
import { checkAuthToken, LOGIN_URL, REGISTER_URL } from '../constants/constants'
import { setUser, checkAuth, logOut, setRegistered, setError } from '../redux/authSlice'


export const authorization = (username, password) => {

  return async dispatch => {
    try {
      const response = await axios.post(LOGIN_URL, {
        username,
        password
      })
      dispatch(setUser(response.data.user))
      localStorage.setItem('token', response.data.access)
    } catch (e) {
      dispatch(setError('Данного аккаунта не существует'))
      localStorage.clear('token')
      dispatch(logOut());
    }
  }
}


export const checkAuthorization = () => {
  return async dispatch => {
    const token = localStorage.getItem("token");
    if (token === undefined) {
      dispatch(logOut());
    } else {

      try {
        let data = {

          token: token,
        }
        const response = await axios.post(checkAuthToken, data)
        dispatch(checkAuth())
      } catch (e) {
        localStorage.clear('token')
        dispatch(logOut());
      }

    }
  }
}
