import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice';
import kaspiOffersSlice from './kaspiOffersSlice';
import productConfigSlice from './productConfigSlice';
import ProductSlice from './productSlice'
import ToastSlice from './toastSlice';


var rootReducer = combineReducers({
    product: ProductSlice,
    toast: ToastSlice,
    productConfig: productConfigSlice,
    kaspiOffers: kaspiOffersSlice,
    auth: authSlice,
})

export const store = configureStore({
    reducer: rootReducer
});